<template>
  <v-card>
        <v-card-title class="text-h4 font-weight-bold">บันทึกข้อมูล<v-spacer/><v-btn icon @click="$emit('onClose')"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
         <v-card-text class="text-left">
              <v-form ref="form" v-model="valid" lazy-validation>
                   <v-row dense>
                  <v-col
                    v-for="(item, index) in form.schema.fields"
                    :key="index"
                    cols="12"
                  >
                    <SelectMultiWidget
                     v-if="item.type === 'select' && visibleCondtion(item) && item.multiple && item.visible"
                      v-bind="item"
                      v-model="item.value"
                    />
                    <!--.schema.fields -->
                    <SelectWidget
                      v-if="item.type === 'select' && visibleCondtion(item) && !item.multiple && item.visible"
                      v-bind="item"
                      v-model="item.value"
                    />
                    <!-- <NumberWidget v-if="item.type==='number'" :question="item"/> -->
                    <TextWidget
                      class="pa-0 ma-0"
                      v-if="item.type === 'text' && visibleCondtion(item) && item.visible"
                      v-bind="item"
                      v-model="item.value"
                    />
                  </v-col>
                </v-row>
              </v-form>
         </v-card-text>
         <v-card-actions>
              <v-row>
              <v-col cols="6" class="pa-2"><v-btn block outlined color="primary" large @click="$emit('onClose')"><span class="text-title-2">ยกเลิก</span></v-btn></v-col>
              <v-col cols="6" class="pa-2"><v-btn block color="primary" large @click="onUpdate()"><span class="text-title-2">บันทึก</span></v-btn></v-col>
          </v-row>
         </v-card-actions>
  </v-card>
</template>

<script>
import SelectWidget from '@/components/preview/SelectWidget'
import SelectMultiWidget from '@/components/preview/SelectMultiWidget'
import TextWidget from '@/components/preview/TextWidget'
import { mapFields } from 'vuex-map-fields'
const required = (msg) => (v) => !!v || msg

export default {
  components: {
    SelectWidget,
    SelectMultiWidget,
    TextWidget
  },
  created () {
    this.buildSchema()
  },
  computed: {
    ...mapFields(['form'])
  },
  methods: {
    buildSchema () {
      this.form.schema.fields.map((obj) => {
        if (obj.required) {
          obj.rules = [required('กรุณากรอกข้อมูล')]
        }
        if (obj.visibleIf) {
          const property = obj.visibleIf.match(/\{(.*?)\}/)
          const val = obj.visibleIf.match(/'([^']+)'/)
          obj.conditionprop = property[1].trim()
          obj.conditionval = val[1].trim()
        }
      })
    },
    buildModel () {
      const model = {}
      console.log(this.form.schema.fields)
      this.form.schema.fields.map((obj) => {
        model[obj.name] = obj.value
      })
      return model
    },
    visibleCondtion (item) {
      if (!item.visible) return false
      if (!item.visibleIf) return true
      const filterObj = this.form.schema.fields.filter(
        (obj) => obj.name === item.conditionprop
      )
      if (!filterObj.length) return true
      if (filterObj[0].value && (filterObj[0].value === item.conditionval)) {
        return true
      } else {
        return false
      }
    },
    onUpdate () {
      this.form.model = this.buildModel()
      this.$emit('onConfirm')
    }
  },
  data () {
    return {
      type: 'iphone',
      scale: 25,
      width: null,
      height: null,
      skins: ['black', 'noShadow'],
      valid: false
    }
  }
}
</script>

<style>

</style>
